<template>
    <v-container
        fill-height
        fluid
    >
        <v-layout
            justify-center
            align-center
        >

            <template v-if="loaded">
                <data-table ref="datatable"
                            :key="uniqueKey"
                            :api-end-point="apiurl"
                            :search="search"
                            :sort-order="sortOrder"
                            :table-name="'Therapist Invoices'"
                            sub-table-name="(Money In)"
                            :isDetail="false"
                            :isExport="true"
                            exportUrl="invoices"
                            :btn-import-white-bg="true"
                            exportBtnText="Download"
                            :export-param="'&status='+ currentTab.name"
                            :isImport="true"
                            :is-custom-filter="false"
                            @import="showImportCsvModal"
                            :pre-selected-filters="filters.selectedFilters"
                            :showGrayHeader="true"
                >
                  <template slot="tabs">
                    <template v-for="tab in tabs">
                      <tabs
                          :current-tab="currentTab"
                          :tab="tab"
                          @changeTab="changeTab"
                      ></tabs>
                    </template>
                  </template>

                    <template slot="filters">
                        <multi-select-filters :filter-id="'therapist_filter'"
                                              :filters="filters.therapistFilter"
                                              :placeholder="'Therapist'"
                                              :query-type="'relationship'"
                                              :relation-ship-name="'therapist'"
                                              :column="'id'"
                                              :operator="'in'"
                        ></multi-select-filters>
                        <date-range-filter :filter-id="'invoice_date_filter'"
                                           :query-type="'Date'"
                                           :column="dateFilterColumn"
                                           :operator="'between'"
                                           :place-holder="dateFilterName"
                                           :parent-class="'mr-5'"
                        >
                        </date-range-filter>
                    </template>
                </data-table>
            </template>


            <!-- Import CSV -->
            <import-csv v-if="importCsvDialog"
                        :visible="importCsvDialog"
                        v-on:hide="hideImportCsvModal"
                        :api-url="'invoices'"
                        v-on:updateTab="fetchTabs"
            >
            </import-csv>

        </v-layout>
    </v-container>
</template>

<script>
import DataTable from "@/components/common/dataTable/DataTable";
import Filters from "@/components/common/dataTable/filters/Filters";
import InsuranceClientDetail from "@/components/transactions/InsuranceClientDetail";
import MultiSelectFilters from "@/components/common/dataTable/filters/MultiSelectFilters";
import DateRangeFilter from "@/components/common/dataTable/filters/DateRangeFilter";
import ImportCsv from "@/components/main/ImportCsv";
import Tabs from "@/components/common/dataTable/tabs";

export default {
    name: "Invoices",
    components: {
        Filters,
        DataTable,
        InsuranceClientDetail,
        MultiSelectFilters,
        DateRangeFilter,
        ImportCsv,
        Tabs
    },
    data() {
        return {
            importCsvDialog: false,
            clientDetailDialog: false,
            apiurl: 'invoices',
            uniqueKey: 'invoices',
            search: {
                placeholder: 'Search by Therapist name, Invoice id…',
                searchAble: true
            },
            sortOrder: [
                {
                    field: 'therapist.full_name',
                    sortField: 'therapist_first_name',
                    direction: 'asc'
                }
            ],
            selectedInvoice: null,
            filters: null,
            loaded: false,
            date: '',
            tabs: null,
            currentTab: null,
            dateFilterColumn: "sent_date",
            dateFilterName: "Sent date ",
        }
    },
    created() {
        this.fetchFilters();
    },
    methods: {
        changeTab(tab) {
          this.apiurl = "invoices?status=" + tab.name;
          this.uniqueKey = tab.name;
          this.currentTab = tab;
          this.dateFilterColumn = tab.name === "Invoice sent" ? "sent_date" : "paid_date";
          this.dateFilterName = tab.name === "Invoice sent" ? "Sent date " : "Date paid ";
        },
        fetchFilters() {
            this.$http.get(this.url + 'filters/invoices').then((res) => {
                if (res.data) {
                    this.filters = res.data;
                    this.fetchTabs();
                } else {
                    alert('there is some problem');
                }
            });
        },
        fetchTabs() {
            this.$http.get(this.url + 'tabs/invoices').then((res) => {
                if (res.data) {
                    this.tabs = res.data;
                    this.currentTab = this.tabs[0];
                    this.apiurl = "invoices?status=" + this.currentTab.name;
                    this.loaded = true;
                } else {
                    alert('there is some problem');
                }
            });
        },
        showImportCsvModal() {
            this.importCsvDialog = true;
        },
        hideImportCsvModal() {
            this.importCsvDialog = false;
        }
    },
    events: {
        'import-csv': function() {
            this.importCsvDialog = true;
            this.showImportCsvModal();
        },
        'client-detail': function (data) {
            this.selectedInvoice = data;
            this.clientDetailDialog = true;
        },
    },
}
</script>
